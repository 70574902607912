<template>
  <component :is="component"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonaConstants from '@/shared/constants/PersonaConstants';
export default {
  name: 'ProductEditManager',

  components: {
    VendorProductEdit: () => import('./VendorProductEdit'),
    SuperProductEdit: () => import('./SuperProductEdit'),
  },

  computed: {
    ...mapGetters('auth', ['user']),
    component() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          return 'SuperProductEdit';
        case PersonaConstants.vendor.value:
        default:
          return 'VendorProductEdit';
      }
    },
  },
};
</script>
